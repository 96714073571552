import { Controller, useForm } from "react-hook-form";
import { FormControl, TextField } from "@mui/material";

type SearchFormProps = {
  onSubmit: (url: string) => void;
  disabled: boolean;
};

export default function SearchForm({ onSubmit, disabled }: SearchFormProps) {
  const { handleSubmit, setValue, control } = useForm<{
    url: string;
  }>({ defaultValues: { url: "" } });

  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values.url))}>
      <FormControl sx={{ m: 1, mt: 2, width: "100ch" }} variant="outlined">
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <TextField
              disabled={disabled}
              label="URL / Share String"
              {...field}
              fullWidth
              onChange={field.onChange}
              value={field.value}
              onClick={() => setValue("url", "")}
            />
          )}
        />
      </FormControl>
    </form>
  );
}
