import { useMutation, useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { supabase } from "../supabase";
import { StoredRecipe } from "./storage";
import { sortBy } from "lodash";

export function useCreateFitbitFood() {
  const [cookies] = useCookies(["access_token"]);
  const mutation = useMutation({
    mutationFn: (queryParamString: string) =>
      fetch(`/api/fitbit/1/user/-/foods.json?${queryParamString}`, {
        method: "POST",
        headers: { authorization: `Bearer ${cookies.access_token}` },
      }),
  });

  const { isPending, data, mutate, mutateAsync } = mutation;
  return { isPending, data, mutate, mutateAsync };
}

export function useSavedRecipes() {
  return useQuery({
    queryKey: ["recipes"],
    queryFn: async () =>
      sortBy(
        (await supabase.from("recipes").select()).data as Array<StoredRecipe>,
        "name"
      ),
  });
}
