import { ThemeProvider } from "@emotion/react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline, createTheme } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Nutriboom from "./pages/Nutriboom";
import Oauth from "./pages/Oauth";
import getCookie from "./pages/getCookie";
import { useDarkMode } from "./state/darkModeState";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  { path: "/", element: <Nutriboom /> },
  { path: "/oauth", element: <Oauth /> },
]);

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const theme = getCookie("theme") || "light";

  const darkMode = useDarkMode((state) => state.darkMode);

  return (
    <ThemeProvider theme={darkMode ? lightTheme : darkTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId="362989706628-k032l27j8a4qtpvtm20iah7ub9tbni5u.apps.googleusercontent.com">
          <RouterProvider router={router} />
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
