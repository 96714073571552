import cryptoRandomString from "crypto-random-string";

export async function generatePKCE() {
  const codeVerifier = getCodeVerifier();
  const codeChallenge = await getCodeChallenge(codeVerifier);
  return { codeVerifier, codeChallenge };
}

export function generateState() {
  return cryptoRandomString({ length: 32, type: "url-safe" });
}

function getCodeVerifier(size = 64) {
  const randomArray = crypto.getRandomValues(new Uint8Array(size));
  return Array.from(randomArray, (dec) =>
    dec.toString(36).padStart(2, "0")
  ).join("");
}

async function getCodeChallenge(codeVerifier: string) {
  const codeVerifierArray = new TextEncoder().encode(codeVerifier);
  return crypto.subtle.digest("SHA-256", codeVerifierArray).then((hash) => {
    const hashArray = new Uint8Array(hash);
    const hashBinaryString = String.fromCharCode(...hashArray);
    const b64Encoded = btoa(hashBinaryString);
    const b64UrlEncoded = b64Encoded.replaceAll("+", "-").replaceAll("/", "_");
    return b64UrlEncoded.replaceAll("=", "");
  });
}
