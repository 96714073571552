import { Skeleton } from "@mui/material";
import TableRow from "./TableRow";

export default function SkeletonTableRow({ index }: { index: number }) {
  return (
    <TableRow
      key={index}
      variant="body"
      name={<Skeleton />}
      amount={<Skeleton />}
      calories={<Skeleton />}
      fats={<Skeleton />}
      saturates={<Skeleton />}
      polyunsaturates={<Skeleton />}
      carbohydrates={<Skeleton />}
      sugars={<Skeleton />}
      fibre={<Skeleton />}
      proteins={<Skeleton />}
      salt={<Skeleton />}
    />
  );
}
