import TableRow from "./TableRow";
import { TableHead } from "@mui/material";

export default function TableHeader() {
  return (
    <TableHead>
      <TableRow
        variant="header"
        name="Food"
        amount="Amount"
        calories="Calories"
        fats="Fats (g)"
        saturates="Saturates (g)"
        polyunsaturates="Polyunsaturates (g)"
        carbohydrates="Carbohydrates (g)"
        sugars="Sugars (g)"
        fibre="Fibre (g)"
        proteins="Proteins (g)"
        salt="Salt (mg)"
      />
    </TableHead>
  );
}
