import { TableRow as MTableRow, TableCell } from "@mui/material";
import { ReactNode } from "react";

type TableRowProps = {
  variant: "header" | "body";
  name: ReactNode;
  amount?: ReactNode;
  calories?: ReactNode;
  fats?: ReactNode;
  saturates?: ReactNode;
  polyunsaturates?: ReactNode;
  carbohydrates?: ReactNode;
  sugars?: ReactNode;
  fibre?: ReactNode;
  proteins?: ReactNode;
  salt?: ReactNode;
  onClick?: (t: any) => void;
  className?: string;
};

export default function TableRow({
  variant,
  name,
  amount,
  calories,
  fats,
  saturates,
  polyunsaturates,
  carbohydrates,
  sugars,
  fibre,
  proteins,
  salt,
  onClick,
  className,
}: TableRowProps) {
  return (
    <MTableRow className={className} onClick={onClick}>
      <TableCell variant="head">{name}</TableCell>
      <TableCell>{amount}</TableCell>
      <TableCell>{calories}</TableCell>
      <TableCell>{fats}</TableCell>
      <TableCell>{saturates}</TableCell>
      <TableCell>{polyunsaturates}</TableCell>
      <TableCell>{carbohydrates}</TableCell>
      <TableCell>{sugars}</TableCell>
      <TableCell>{fibre}</TableCell>
      <TableCell>{proteins}</TableCell>
      <TableCell>{salt}</TableCell>
    </MTableRow>
  );
}
