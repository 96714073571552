import { Button, Snackbar } from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useCreateFitbitFood } from "./queries";
import convertFoodDataToFitbitQueryString, {
  NutritionalValues,
} from "./convertFoodDataToFitbitQuery";

const wordsToStrip = [
  "Duchy",
  "Organic",
  "Essential",
  "Waitrose",
  "Large",
  "in Water",
  "Mixed",
  "in Natural Juice",
  "British",
  "Free Range",
  "Strength",
  "No.1",
  "Scottish",
  "Fillet",
  "Fillets",
  "Natural",
  "Filippo Berio",
  "Cheese",
  "Fairtrade",
  "Perfectly",
  "Ripe",
  "Old El Paso",
  "Gran Luchito",
  "Large",
  "Fage",
  "Granulated",
  "Cooks&#x27;",
  "Ingredients",
  "Heinz",
  "New York Bakery Co",
  "Philadelphia",
  "Tubs",
];

function sanitizeName(name: string) {
  let result = name;
  wordsToStrip.forEach((word) => {
    result = result.replaceAll(word, "");
  });
  return result.trimStart().trimEnd();
}

export default function AddToFitBitButton({
  names,
  formName,
  totals,
  divisor,
}: {
  names: Array<string>;
  formName: string;
  totals: NutritionalValues;
  divisor: number;
}) {
  var truncatedDescription = names.map(sanitizeName).join(",");
  if (truncatedDescription.length > 175) {
    truncatedDescription = truncatedDescription.substring(0, 172) + "...";
  }

  const [cookies] = useCookies(["access_token"]);
  const [fitbitToastOpen, setFitbitToastOpen] = useState(false);

  const createFitbitFood = useCreateFitbitFood();
  if (!cookies.access_token) {
    return null;
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={fitbitToastOpen}
        onClose={() => setFitbitToastOpen(false)}
        autoHideDuration={6000}
        message="Food has been added to fitbit!"
      />
      <Button
        sx={{ marginLeft: "2rem" }}
        disabled={
          createFitbitFood.isPending ||
          formName === "" ||
          formName === undefined
        }
        variant="outlined"
        onClick={() => {
          const queryParams = convertFoodDataToFitbitQueryString(
            formName,
            encodeURI(truncatedDescription),
            totals,
            divisor
          );

          createFitbitFood.mutateAsync(queryParams).then((res) => {
            res.json().then(async (data) => {
              console.info(data);
              setFitbitToastOpen(true);
            });
          });
        }}
      >
        Add food to fitbit
      </Button>
    </>
  );
}
