import { create } from "zustand";
import getCookie from "../pages/getCookie";

type DarkModeState = {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
};

export const useDarkMode = create<DarkModeState>((set) => {
  const darkModeOn = getCookie("theme") === "dark";
  return {
    darkMode: darkModeOn,
    setDarkMode: (darkMode: boolean) => {
      set({ darkMode });
      document.cookie = `theme=${darkMode ? "dark" : "light"}`;
    },
  };
});
