import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

export default function Oauth() {
  const [searchParams] = useSearchParams();

  const state = localStorage.getItem("state") || "";
  const code = searchParams.get("code") || "";
  const codeVerifier = localStorage.getItem("codeVerifier") || "";

  const mutation = useMutation({
    mutationFn: () =>
      fetch("https://api.fitbit.com/oauth2/token", {
        method: "POST",
        body: new URLSearchParams({
          client_id: "23PK5F",
          grant_type: "authorization_code",
          redirect_uri: "https://nutriboom.vercel.app/oauth",
          code,
          state,
          code_verifier: codeVerifier,
        }),
      }),
  });

  useEffect(() => {
    if (mutation.isIdle && !mutation.isSuccess) {
      mutation.mutate();
    }
  }, [mutation, mutation.mutate]);

  useEffect(() => {
    if (mutation.data) {
      mutation.data.json().then((data) => {
        const d = new Date();
        d.setTime(d.getTime() + data.expires_in * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = `access_token=${data.access_token}; ${expires}; path=/`;
      });
    }
  }, [mutation.data]);
  return mutation.data ? (
    <Navigate to="/" replace={true} />
  ) : (
    <div>Logging in...</div>
  );
}
