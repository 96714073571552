import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Controller, useForm } from "react-hook-form";

export default function QuantityEditor({
  open,
  name,
  close,
  value,
  onChange,
  deleteItem,
}: {
  open: boolean;
  name: string;
  close: () => void;
  value: {
    amount: number;
    unit: string;
  };
  onChange: (amount: number) => void;
  deleteItem: () => void;
}) {
  const { handleSubmit, control } = useForm<{ amount: number }>({
    defaultValues: { amount: value.amount },
  });

  const truncatedName = name.length > 20 ? name.slice(0, 20) + "..." : name;

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Edit {truncatedName}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit((value) => onChange(value.amount))}>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Quantity"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {value.unit}
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
              mt="1rem"
            >
              <Button variant="outlined" color="warning" onClick={deleteItem}>
                Delete
              </Button>
              <Button type="submit" variant="outlined" color="primary">
                Save
              </Button>
            </Box>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  );
}
