import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { useDarkMode } from "../state/darkModeState";
import { supabase } from "../supabase";
import FitbitButton from "./FitbitButton";

export default function ButtonAppBar() {
  const darkMode = useDarkMode((state) => state.darkMode);
  const setDarkMode = useDarkMode((state) => state.setDarkMode);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ alignItems: "space-between" }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Nutriboom!
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={!darkMode}
                    onChange={() => setDarkMode(!darkMode)}
                  />
                }
                label="Dark Mode"
              />
            </FormGroup>
          </Box>
          <GoogleLogin
            theme="filled_blue"
            onSuccess={async (response) => {
              const { data, error } = await supabase.auth.signInWithIdToken({
                provider: "google",
                token: response.credential || "",
              });
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />

          <FitbitButton />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
