import classes from "./Nutriboom.module.css";
import { FoodData } from "../types";
import QuantityEditor from "./QuantityEditor";
import { useState } from "react";
import TableRow from "./TableRow";

export default function Nutrition({
  foodData,
  onChangeQuantity,
  deleteItem,
}: {
  foodData: FoodData;
  onChangeQuantity: (value: number) => void;
  deleteItem: () => void;
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        onClick={() => setOpen(true)}
        className={classes.tableRow}
        variant="body"
        name={foodData.title}
        amount={
          <div>
            {foodData.quantity.amount}
            {foodData.quantity.unit}
          </div>
        }
        calories={`${foodData.energy || 0}`}
        fats={`${foodData.fat || 0}`}
        saturates={`${foodData.saturates || 0}`}
        polyunsaturates={`${foodData.polyunsaturates || 0}`}
        carbohydrates={`${foodData.carbohydrate || 0}`}
        sugars={`${foodData.sugars || 0}`}
        fibre={`${foodData.fibre || 0}`}
        proteins={`${foodData.protein || 0}`}
        salt={`${foodData.salt || 0}`}
      />
      <QuantityEditor
        open={open}
        name={foodData.title}
        value={foodData.quantity}
        close={() => setOpen(false)}
        onChange={(amount) => {
          onChangeQuantity(amount);
          setOpen(false);
        }}
        deleteItem={deleteItem}
      />
    </>
  );
}
