export type NutritionalValues = {
  energy: number;
  fat: number;
  saturates: number;
  salt: number;
  carbohydrate: number;
  fibre: number;
  sugars: number;
  protein: number;
};

function totalsToFitbitQuery(
  nutritionalValues: NutritionalValues,
  divisor: number
) {
  const toUnit = (value: number) => (value / divisor).toFixed(2);

  return `calories=${Math.round(Number(toUnit(nutritionalValues.energy)))}
  &totalFat=${toUnit(nutritionalValues.fat)}
  &saturatedFat=${toUnit(nutritionalValues.saturates)}
  &sodium=${toUnit(nutritionalValues.salt)}
  &totalCarbohydrate=${toUnit(nutritionalValues.carbohydrate)}
  &dietaryFiber=${toUnit(nutritionalValues.fibre)}
  &sugars=${toUnit(nutritionalValues.sugars)}
  &protein=${toUnit(nutritionalValues.protein)}`;
}

export default function convertFoodDataToFitbitQueryString(
  name: string,
  description: string,
  foodData: NutritionalValues,
  divisor: number
) {
  return `name=${name}
  &defaultFoodMeasurementUnitId=${147}
  &defaultServingSize=100
  &formType=DRY
  &description=TBD
  &brand=${description}
  &${totalsToFitbitQuery(foodData, divisor)}`;
}
