import { Button } from "@mui/material";
import { generatePKCE, generateState } from "./generatePKCE";
import FitbitIcon from "@mui/icons-material/Fitbit";
import DoneIcon from "@mui/icons-material/Done";
import { useCookies } from "react-cookie";

export default function FitbitButton() {
  const [cookies] = useCookies(["access_token"]);
  const dot = () =>
    generatePKCE().then((pkce) => {
      const state = generateState();
      const url = `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=23PK5F&scope=nutrition&state=${state}&code_challenge=${pkce.codeChallenge}&code_challenge_method=S256&redirect_uri=https%3A%2F%2Fnutriboom.vercel.app%2Foauth`;
      localStorage.setItem("state", state);
      localStorage.setItem("codeVerifier", pkce.codeVerifier);
      window.location.href = url;
    });

  return cookies.access_token ? (
    <Button
      variant="outlined"
      color="inherit"
      sx={{ marginLeft: "2rem" }}
      disabled={true}
    >
      <DoneIcon /> You are logged in with fitbit
    </Button>
  ) : (
    <Button
      variant="outlined"
      color="inherit"
      onClick={dot}
      sx={{ marginLeft: "2rem" }}
    >
      <FitbitIcon /> Login with FitBit
    </Button>
  );
}
