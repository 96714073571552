import {
  Button,
  FormControl,
  Snackbar,
  Table,
  TableBody,
  TextField,
} from "@mui/material";
import { UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import { reduce, unzip, zipWith } from "lodash";
import { useEffect, useState } from "react";
import Nutrition from "./Nutrition";
import SearchForm from "./SearchForm";

import { Save } from "@mui/icons-material";
import { GoogleLogin } from "@react-oauth/google";
import { User } from "@supabase/auth-js";
import { Session } from "@supabase/supabase-js";
import { Controller, useForm } from "react-hook-form";
import { supabase } from "../supabase";
import { FoodData } from "../types";
import AddToFitBitButton from "./AddToFitBitButton";
import HeaderBar from "./HeaderBar";
import RecipeView from "./RecipeView";
import SkeletonTableRow from "./SkeletonTableRow";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { NutritionalValues } from "./convertFoodDataToFitbitQuery";
import transformNutritionData from "./transformNutritionData";
import useInvalidateQuery from "./useInvalidateQuery";

function stripUnit(value: any) {
  if (!value) return 0;
  return parseFloat(
    decodeURI(value)
      .replace("g", "")
      .replace("kcal", "")
      .replace("&lt;", "")
      .replace("<", "")
  );
}

export default function NutriboomWrapper() {
  const [user, setUser] = useState<User | null>(null);
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    async function getUser() {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setSession(session);
      setUser(user);
    }
    getUser();
  }, []);

  return user ? (
    <Nutriboom userUid={user.id} />
  ) : (
    <GoogleLogin
      onSuccess={async (response) => {
        await supabase.auth.signInWithIdToken({
          provider: "google",
          token: response.credential || "",
        });
      }}
      onError={() => {
        console.log("Login Failed");
      }}
    />
  );
}

function Nutriboom({ userUid }: { userUid: string }) {
  const [urls, setUrls] = useState<string[]>([]);
  const [cookedAmount, setCookedAmount] = useState<number | null>(null);
  const invalidateRecipes = useInvalidateQuery("recipes");

  const results = useQueries({
    queries: urls.map((url) => ({
      queryKey: ["nutrition", url],
      queryFn: () =>
        fetch(
          `/api/nutrition/${url.replace("https://www.waitrose.com/", "")}`
        ).then(async (res) => {
          const v = transformNutritionData(await res.text());

          return v;
        }),
      staleTime: Infinity,
    })),
    combine: (res: UseQueryResult<FoodData>[]) => {
      return res.map((r) => ({ data: r.data, isLoading: r.isLoading }));
    },
  });
  const anyLoading = results.some((r) => r.isLoading);

  const [multipliers, setMultipliers] = useState<number[]>([]);

  const keysToSum = [
    "quantity",
    "energy",
    "fat",
    "saturates",
    "polyunsaturates",
    "carbohydrate",
    "sugars",
    "fibre",
    "protein",
    "salt",
  ];

  const [rows, setRows] = useState<[FoodData | undefined, boolean][]>([]);

  useEffect(() => {
    setRows(
      results.map(({ data, isLoading }, index) => {
        if (isLoading || !data) return [undefined, isLoading];
        const food = data;
        const multiplier = multipliers[index] || 1;
        return [
          {
            ...food,
            quantity: {
              ...food.quantity,
              amount: Math.round(food.quantity.amount * multiplier),
            },
            energy: Math.round(food.energy * multiplier),
            fat: Math.round(food.fat * multiplier),
            saturates: Math.round(food.saturates * multiplier),
            polyunsaturates: Math.round(food.polyunsaturates * multiplier),
            carbohydrate: Math.round(food.carbohydrate * multiplier),
            sugars: Math.round(food.sugars * multiplier),
            fibre: Math.round(food.fibre * multiplier),
            protein: Math.round(food.protein * multiplier),
            salt: Math.round(food.salt * multiplier),
          },
          isLoading,
        ];
      })
    );
  }, [results, multipliers]);

  const totals = reduce(
    rows,
    (acc, [nutrition]) => {
      if (!nutrition) return acc;
      keysToSum.forEach((key) => {
        if (key === "quantity") {
          acc[key] = (acc[key] || 0) + nutrition[key].amount;
        } else {
          acc[key] =
            (acc[key] || 0) +
            stripUnit(nutrition[key as keyof typeof nutrition]);
        }
      });
      return acc;
    },
    Object.fromEntries(keysToSum.map((key) => [key, 0]))
  );

  const { handleSubmit, setValue, control, watch } = useForm<{
    cookedAmount: number;
    name: string;
  }>();
  const formName = watch("name");

  const cookedAmountWatch = watch("cookedAmount");
  useEffect(() => {
    if (cookedAmountWatch) {
      setCookedAmount(cookedAmountWatch);
    }
  }, [cookedAmountWatch]);

  const [open, setOpen] = useState(false);

  const divisor = cookedAmount ? cookedAmount / 100 : totals.quantity / 100;

  return (
    <>
      <HeaderBar />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={6000}
        message="Food has been added to fitbit!"
      />

      <SearchForm
        disabled={anyLoading}
        onSubmit={(url) => {
          if (!url.startsWith("https://")) {
            const recipe = JSON.parse(window.atob(url));
            if (!recipe.recipe) {
              return;
            }
            const [urls, multipliers] = unzip(recipe.recipe) as [
              string[],
              number[]
            ];
            setUrls(urls);
            setMultipliers(multipliers);
            setCookedAmount(recipe.cooked_amount);
          } else {
            setUrls([...urls, url]);
          }
        }}
      />
      <Table>
        <TableHeader />
        <TableBody>
          {rows.map(([data], index) =>
            data ? (
              <Nutrition
                key={index}
                foodData={data}
                onChangeQuantity={(amount) => {
                  const [rowToUpdate] = rows[index];
                  if (!rowToUpdate) return;
                  const currentMult = multipliers[index] || 1;
                  const multiplier =
                    amount / (rowToUpdate.quantity.amount / currentMult);
                  const multipliersCopy = [...multipliers];
                  multipliersCopy[index] = multiplier;
                  setMultipliers(multipliersCopy);
                }}
                deleteItem={() => {
                  setUrls(urls.filter((_, i) => i !== index));
                  setMultipliers(multipliers.filter((_, i) => i !== index));
                }}
              />
            ) : (
              <SkeletonTableRow index={index} />
            )
          )}

          <TableRow
            key="totals"
            variant="body"
            name="Total"
            amount={
              cookedAmount
                ? `${cookedAmount}g`
                : totals.quantity
                ? `${totals.quantity}g`
                : ""
            }
            calories={`${totals.energy} kcal`}
            fats={`${totals.fat}g`}
            saturates={`${totals.saturates}g`}
            polyunsaturates={`${totals.polyunsaturates}g`}
            carbohydrates={`${totals.carbohydrate}g`}
            sugars={`${totals.sugars}g`}
            fibre={`${totals.fibre}g`}
            proteins={`${totals.protein}g`}
            salt={`${totals.salt}g`}
          />
          <TableRow
            key="100gtotals"
            variant="body"
            name="Per 100g"
            amount={totals.quantity ? `100g` : ""}
            calories={`${Math.ceil(totals.energy / divisor)} kcal`}
            fats={`${Math.ceil(totals.fat / divisor)}g`}
            saturates={`${Math.ceil(totals.saturates / divisor)}g`}
            polyunsaturates={`${Math.ceil(totals.polyunsaturates / divisor)}g`}
            carbohydrates={`${Math.ceil(totals.carbohydrate / divisor)}g`}
            sugars={`${Math.ceil(totals.sugars / divisor)}g`}
            fibre={`${Math.ceil(totals.fibre / divisor)}g`}
            proteins={`${Math.ceil(totals.protein / divisor)}g`}
            salt={`${Math.ceil(totals.salt / divisor)}g`}
          />
        </TableBody>
      </Table>

      <form
        onSubmit={handleSubmit(async ({ name }) => {
          supabase
            .from("recipes")
            .insert({
              name,
              recipe: zipWith(urls, multipliers, (url, multiplier) => [
                url,
                multiplier || 1,
              ]),
              cooked_amount: cookedAmount,
              user_uid: userUid,
            })
            .select()
            .then(invalidateRecipes);
        })}
      >
        <FormControl
          sx={{
            m: 1,
            mt: 2,
            width: "100ch",
            display: "flex",
            flexDirection: "row",
          }}
          variant="outlined"
        >
          <Controller
            name="cookedAmount"
            control={control}
            render={({ field }) => (
              <TextField
                sx={{ marginRight: "1rem" }}
                label="Weight after cooking"
                {...field}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                label="Name"
                {...field}
                onChange={field.onChange}
                value={field.value}
                onClick={() => setValue("name", "")}
              />
            )}
          />

          <Button
            color="primary"
            sx={{ ml: "1rem" }}
            type="submit"
            variant="outlined"
          >
            <Save fontSize="small" sx={{ mr: "4px" }} /> Save recipe
          </Button>
          <AddToFitBitButton
            names={rows.map((row) => row[0]?.title || "")}
            formName={formName}
            totals={totals as NutritionalValues}
            divisor={divisor}
          />
        </FormControl>
      </form>

      <hr />

      <RecipeView
        setUrls={setUrls}
        setMultipliers={setMultipliers}
        setCookedAmount={setCookedAmount}
      />
    </>
  );
}
